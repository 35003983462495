import * as React from "react";
import ReactMarkdown from "react-markdown";
import ReactDOMServer from "react-dom/server";

export function* boxModeGen() {
  const VALUES = ["teal", "orange", "blue"];
  for (let index = 0; index < VALUES.length; index++) {
    yield VALUES[index];
    if (index === VALUES.length - 1) {
      index = -1;
    }
  }
}

export function stripHTML(html) {
  return html.replace(/(<([^>]+)>)/gi, "").trim();
}

export function markdownSeoDescription(description) {
  return stripHTML(
    ReactDOMServer.renderToStaticMarkup(
      <ReactMarkdown children={description} />
    )
  );
}
